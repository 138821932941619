const runTimeConfig = require('../configs/runtime.config');

export default async function getProfilePic() {
  // eslint-disable-next-line no-undef
  const myHeaders = new Headers();
  myHeaders.append('response', 'image/jpeg');
  myHeaders.append(
    'Authorization',
    `Bearer ${runTimeConfig.accessTokens.graph}`,
  );
  myHeaders.append('Cache-Control', 'no-cache');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  const response = await fetch(
    'https://graph.microsoft.com/v1.0/me/photo/$value',
    requestOptions,
    // eslint-disable-next-line no-console
  ).catch((error) => console.log('error', error));

  if (response.status === 404) return false;
  const res = await response.blob();

  return window.webkitURL.createObjectURL(res);
}
