import { Box, Typography } from '@mui/material';
import React from 'react';

function Footer() {
  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up('sm')]: {
          position: 'fixed',
          bottom: 0,
          background: '#eaeff1',
          padding: theme.spacing(1),
          height: 15,
          width: '100%',
          zIndex: 9,
        },
      })}
    >
      <Typography variant="body2" color={'textSecondary'} align="center">
        {'Copyright © Kmart  '}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

export default Footer;
