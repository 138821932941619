import { useState, useEffect } from 'react';
import jwt from 'jwt-decode';
import {
  msalApp,
  requiresInteraction,
  fetchMsGraph,
  GRAPH_ENDPOINTS,
  GRAPH_REQUESTS,
  BACKEND_REQUESTS,
} from '../utils/auth_utils';
import { getNameInitials } from '../utils';
import { apiService } from '../services';
import getProfilePic from '../services/image.service';
import { authorizationApi } from '../configs';
import useAuthProviderStore from '../stores/auth_provider.store';

const runTimeConfig = require('../configs/runtime.config');

const useRedirectFlow = true;

const AuthProvider = () => {
  let localAccount;

  const { userAccount, accessTokens } = useAuthProviderStore.getState();

  const [error, setError] = useState();

  const acquireToken = async (request, redirect) => {
    return msalApp.acquireTokenSilent(request).catch((err) => {
      // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
      // due to consent or interaction required ONLY
      if (requiresInteraction(err.errorCode)) {
        return redirect
          ? msalApp.acquireTokenRedirect(request)
          : msalApp.acquireTokenPopup(request);
      }
      // eslint-disable-next-line no-console
      console.error('Non-interactive error:', err.errorCode);
      return undefined;
    });
  };

  const acquireBackendToken = async () => {
    const tokenResponse = await acquireToken(
      BACKEND_REQUESTS.OSB,
      useRedirectFlow,
    ).catch(() => {
      setError({ error: 'Unable to acquire access token for reading email.' });
    });
    if (tokenResponse) {
      useAuthProviderStore.getState().updateAccessTokens({
        ...accessTokens,
        backend: tokenResponse.accessToken,
      });
      // Auto renewing tokens
      const tokenObj = jwt(tokenResponse.accessToken);
      setTimeout(
        acquireBackendToken,
        tokenObj.exp * 1000 - new Date().getTime(),
      );
      return tokenResponse.accessToken;
    }
    return false;
  };

  const acquireGraphProfile = async (userAccount) => {
    const tokenResponse = await acquireToken(
      GRAPH_REQUESTS.LOGIN,
      useRedirectFlow,
    ).catch((err) => {
      setError({ error: err.message });
    });

    if (tokenResponse) {
      const graphProfile = await fetchMsGraph(
        GRAPH_ENDPOINTS.ME,
        tokenResponse.accessToken,
      ).catch(() => {
        setError({ error: 'Unable to fetch Graph profile.' });
      });
      if (graphProfile) {
        useAuthProviderStore.getState().updateAccessTokens({
          ...accessTokens,
          graph: tokenResponse.accessToken,
        });
        // Auto renewing tokens
        const tokenObj = jwt(tokenResponse.accessToken);
        graphProfile.nameInitials = getNameInitials(userAccount.name);
        useAuthProviderStore.getState().updateGraphProfile(graphProfile);
        const userProfilepic = await getProfilePic();
        useAuthProviderStore.getState().updateProfilePic(userProfilepic);
        setTimeout(
          acquireGraphProfile,
          tokenObj.exp * 1000 - new Date().getTime(),
          userAccount,
        );
        return tokenResponse.accessToken;
      }
    }
    return false;
  };

  const acquireAuthorization = async () => {
    try {
      const token = await apiService
        .post(authorizationApi.default, { requested_for: ['apps'] })
        .then((res) => res.data?.auth_token);
      if (!token) {
        useAuthProviderStore.getState().updateUserPermissions(null);
        return undefined;
      }

      const tokenObj = jwt(token, process.env.REACT_APP_PublicKey);
      // eslint-disable-next-line no-throw-literal
      if (!tokenObj.usc) throw 'No permissions';
      useAuthProviderStore.getState().updateUserPermissions(tokenObj);
    } catch (err) {
      console.log(err, 'err');
      useAuthProviderStore.getState().updateUserPermissions(null);
    }
    return undefined;
  };

  const updateAccessTokens = () => {
    if (accessTokens) {
      runTimeConfig.accessTokens = accessTokens;
    }
  };

  const fetchUserAccount = async () => {
    localAccount = await msalApp.getAccount();
    if (localAccount) {
      const backToken = await acquireBackendToken();
      const graphProfileToken = await acquireGraphProfile(localAccount);
      const tokenList = {
        backend: backToken,
        graph: graphProfileToken,
      };
      useAuthProviderStore.getState().updateAccessTokens(tokenList);
      runTimeConfig.accessTokens = tokenList;
      await acquireAuthorization();
      useAuthProviderStore.getState().updateUserAccount(localAccount);
    } else msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
  };

  const redirectCallback = () => {
    msalApp.handleRedirectCallback((err) => {
      if (error) {
        const errorMessage = err.errorMessage
          ? err.errorMessage
          : 'Unable to acquire access token.';
        setError({
          error: errorMessage,
        });
      }
    });
  };

  useEffect(() => {
    fetchUserAccount();
  }, [localAccount?.idToken?.exp * 1000 > new Date().getTime()]);

  useEffect(() => {
    updateAccessTokens();
  }, [accessTokens]);

  useEffect(() => {
    redirectCallback();
  }, [error]);

  if (userAccount) {
    return { userAccount: userAccount };
  }
  return { userAccount: null };
};

export default AuthProvider;
