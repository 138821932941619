import create from 'zustand';
import { devtools } from 'zustand/middleware';

const initailState = {
  searchText: '',
};

const store = (set) => ({
  ...initailState,
  setSearchText: (text) => {
    set({ searchText: text });
  },
});

const useAppsStore = create(devtools(store, { name: 'AppsStore' }));

export default useAppsStore;
