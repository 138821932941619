import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './footer';
import Topbar from './top_bar';
import { Box } from '@mui/material';

function index() {
  return (
    <Box
      sx={{
        backgroundColor: '#f7f9fc',
        display: 'flex',
        minHeight: '100dvh',
        flexDirection: 'column',
      }}
    >
      <Topbar />
      <Box
        sx={(theme) => ({
          display: 'flex',
          //   marginTop: 10,
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            flex: 1,
          },
        })}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
}

export default index;
