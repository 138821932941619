/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import appsAction from './actions';
import ProgressLoader from '../../../components/progress_bar';
import useAppsStore from '../../../stores/apps.store';
import { shadowColors, iconColors } from '../../../utils/card_colors';
import useAuthProviderStore from '../../../stores/auth_provider.store';

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '220px',
  objectFit: 'cover',
  [theme.breakpoints.up('md')]: {
    height: '230px',
  },
  [theme.breakpoints.down('md')]: {
    height: '130px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '95px',
  },
}));

function search(arr, searchKey) {
  return arr.filter((obj) =>
    Object.keys(obj).some((key) =>
      obj[key].toLowerCase().includes(searchKey.toLowerCase()),
    ),
  );
}

const WelcomeView = () => {
  const [appsLoader, setAppsLoader] = useState(false);
  const [allApps, setAllApps] = useState([]);
  const [apps, setApps] = useState([]);
  const searchText = useAppsStore((state) => state.searchText);
  const { userPermissions } = useAuthProviderStore((state) => ({
    userPermissions: state.userPermissions,
  }));

  useEffect(() => {
    setAppsLoader(true);
    userPermissions &&
      appsAction.getApps().then((data) => {
        if (data) {
          setAppsLoader(false);
          setAllApps(data.apps);
          setApps(data.apps);
        }
      });
  }, [userPermissions]);

  const onSearch = (text) => {
    setApps(search(allApps, text));
  };

  useEffect(() => {
    onSearch(searchText);
  }, [searchText]);

  return (
    <Box sx={{ height: 'inherit', position: 'relative' }}>
      <Box
        sx={(theme) => ({
          position: 'relative',
        })}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/images/success_factor_banner.jpg`}
          alt="Kmart"
        />
        <Typography
          style={{ fontFamily: 'serif' }}
          sx={(theme) => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            marginBottom: '1.5rem',
            textAlign: 'center',
            fontSize: '58px',
            textShadow: '2px 1px #eee',
            fontWeight: 900,
            color: 'rgb(47 64 72)',
            [theme.breakpoints.up('md')]: {
              fontSize: '48px',
            },
            [theme.breakpoints.down('md')]: {
              fontSize: '30px',
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: '25px',
              fontWeight: 600,
            },
          })}
          variant="h1"
        >
          Welcome to Supplier Portal
        </Typography>
      </Box>

      {!allApps?.length > 0 && !appsLoader && (
        <Typography
          style={{
            color: '#7e7e7e',
            display: 'flex',
            width: '100%',
            margin: 'auto',
          }}
          component="h4"
        >
          No Subscribed Applications found, please contact the admin to
          subscribe.
        </Typography>
      )}

      {appsLoader ? (
        <ProgressLoader label="Getting Apps" />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              width: '85%',
              display: 'flex',
              justifyContent: 'space-evenly',
              [theme.breakpoints.only('sm')]: {
                width: '85%',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexWrap: 'wrap',
                padding: '15px 45px',
                [theme.breakpoints.up('md')]: {
                  padding: '15px 0px 100px',
                },
                [theme.breakpoints.down('md')]: {
                  padding: '20px 0px',
                },
                [theme.breakpoints.down('sm')]: {
                  // padding: '8px 70px 15px',
                },
              })}
            >
              {apps?.map((appData, index) => {
                const colors = iconColors;
                const shades = shadowColors;
                const iconsNames = {
                  'Open Costing': 'request_quote',
                  'Overseas Stock Buffering': 'widgets',
                  'Supplier Factory Registration System': 'factory',
                  'Partner Konnect': 'diversity_3',
                  'Projections & Commitments': 'analytics',
                  'Reports And Dashboards': 'data_exploration',
                };

                return (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    sx={(theme) => ({
                      position: 'relative',
                      backgroundColor: '#FFF',
                      width: '220px',
                      height: '130px',
                      padding: '10px 0px',
                      borderRadius: '12px',
                      boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
                      color: '#546e7a',
                      textAlign: 'center',
                      cursor: 'pointer',
                      overflow: 'hidden',
                      '&:hover': {
                        color: '#fff',
                      },
                      '&:hover .icon_background_color': {
                        color: '#fff',
                      },
                      '&:hover .bg_color': {
                        height: '100px',
                        width: '100px',
                        transform: ' scale(10)',
                      },
                      [theme.breakpoints.up('md')]: {
                        margin: '18px 14px',
                        width: '285px',
                        height: '85px',
                      },
                      [theme.breakpoints.only('sm')]: {
                        margin: '15px 20px',
                        width: '265px',
                        height: '95px',
                      },
                      [theme.breakpoints.down('sm')]: {
                        margin: '12px 20px',
                        width: '265px',
                        height: '80px',
                      },
                    })}
                    onClick={() => appData?.url && window.open(appData?.url)}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 2,
                      }}
                    >
                      <Box
                        sx={{
                          zIndex: 2,
                          padding: 1,
                        }}
                      >
                        <Box>
                          <Icon
                            className="icon_background_color"
                            sx={(theme) => ({
                              color: colors[index] || '#555',
                              fontSize: '22px',
                              fontWeight: 900,
                            })}
                          >
                            {/* https://fonts.google.com/icons */}
                            {iconsNames[appData.name] || 'drag_pan'}
                          </Icon>
                        </Box>
                        <Typography
                          className="title"
                          variant="subtitle2"
                          sx={(theme) => ({
                            fontFamily: 'serif',
                            fontSize: '18px',
                            letterSpacing: 0.4,
                            WebkitTransition: 'all .4s ease',
                            OTransition: 'all .4s ease',
                            transition: 'all .4s ease',
                            [theme.breakpoints.down('xl')]: {
                              letterSpacing: 0.5,
                            },
                            [theme.breakpoints.down('sm')]: {
                              fontSize: '20px',
                            },
                          })}
                        >
                          {appData.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="bg_color"
                      sx={{
                        height: '90px',
                        width: '90px',
                        zIndex: 1,
                        position: 'absolute',
                        top: '-75px',
                        right: '-75px',
                        borderRadius: '50%',
                        WebkitTransition: 'all .5s ease',
                        OTransition: 'all .5s ease',
                        transition: 'all .5s ease',
                        backgroundColor: colors[index],
                      }}
                    ></Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WelcomeView;
