import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export default function CustomProgress(props) {
  const { label } = props;
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 1300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />

        <Typography>{label}...</Typography>
      </Box>
    </Box>
  );
}

CustomProgress.defaultProps = {
  label: 'loading',
};

CustomProgress.propTypes = {
  label: PropTypes.string,
};
