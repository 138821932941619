import axios from 'axios';
import _ from 'lodash';
import { apiVersion } from '../configs';

const runTimeConfig = require('../configs/runtime.config');

const get = async (url, params) =>
  axios.get(process.env.REACT_APP_DOMAIN + apiVersion + url, { params }); // creating multiple Api requests and storing in a array

const deleteMethod = (url) =>
  axios({
    method: 'delete',
    url: process.env.REACT_APP_DOMAIN + apiVersion + url,
  });

const put = (url, params) =>
  axios({
    method: 'put',
    url: process.env.REACT_APP_DOMAIN + apiVersion + url,
    data: params,
  });

const post = (url, params) =>
  axios({
    method: 'post',
    url: process.env.REACT_APP_DOMAIN + apiVersion + url,
    data: params,
  });

const uploadFile = (url, key, file, params = {}, uploadProgressEvent) => {
  const formData = new FormData();
  Object.keys(params).forEach((k) => formData.append(k, params[k]));
  formData.append(key, file);
  const cancelToken = axios.CancelToken.source();
  return axios({
    method: 'post',
    url: process.env.REACT_APP_DOMAIN + apiVersion + url,
    data: formData,
    cancelToken: cancelToken.token,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (_.isFunction(uploadProgressEvent))
        uploadProgressEvent(percentCompleted, cancelToken);
    },
  });
};

const exportFile = (url, params, downloadProgressEvent) => {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_DOMAIN + apiVersion + url,
    data: params,
    responseType: 'arraybuffer',
    onDownloadProgress: (progressEvent) => {
      const percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (_.isFunction(downloadProgressEvent))
        downloadProgressEvent(percentCompleted);
    },
  }).then((res) => {
    const filename = res.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim();

    const finalUrl = window.URL.createObjectURL(
      new Blob([res.data], { type: res.headers['content-type'] }),
    );
    const link = document.createElement('a');
    link.href = finalUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  });
};

const viewFile = (url, downloadProgressEvent) => {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_DOMAIN + apiVersion + url,
    responseType: 'arraybuffer',
    onDownloadProgress: (progressEvent) => {
      const percentCompleted = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      if (_.isFunction(downloadProgressEvent))
        downloadProgressEvent(percentCompleted);
    },
  }).then((res) => {
    const filename = res.headers['content-disposition']
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .trim();

    const finalUrl = window.URL.createObjectURL(
      new Blob([res.data], { type: res.headers['content-type'] }),
    );
    const link = document.createElement('a');
    link.href = finalUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    window.open(finalUrl);
  });
};

const concurrent = (requests) => {
  return axios.all(requests);
};

axios.interceptors.request.use(
  (_req) => {
    const req = _.cloneDeep(_req);
    if (runTimeConfig && runTimeConfig.accessTokens)
      req.headers = {
        ..._req.headers,
        Authorization: `Bearer ${runTimeConfig.accessTokens.backend}`,
      };

    return req;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error;
  },
);

export const apiService = {
  get,
  post,
  put,
  concurrent,
  deleteMethod,
  exportFile,
  uploadFile,
  viewFile,
};

export default apiService;
