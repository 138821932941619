import { AppBar, Avatar, Box, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import InputIcon from '@mui/icons-material/Input';
import SearchBar from '../../components/search_bar';
import permissionManager from '../../lib/permission.manager';
import useAuthProviderStore from '../../stores/auth_provider.store';
import useAppsStore from '../../stores/apps.store';

const Image = styled('img')(({ theme }) => ({
  height: 25,
  [theme.breakpoints.down('sm')]: {
    height: 18,
  },
}));

const ImageTarget = styled('img')(({ theme }) => ({
  height: 45,
  marginLeft: 15,
  [theme.breakpoints.down('sm')]: {
    margin: '0px 10px',
    height: 35,
  },
}));

function TopBar() {
  const { userPermissions, graphProfile, profilePic } = useAuthProviderStore(
    (state) => ({
      userPermissions: state.userPermissions,
      profilePic: state.profilePic,
      graphProfile: state.graphProfile,
    }),
  );

  const { setSearchText } = useAppsStore();

  const { signOutUser } = useAuthProviderStore();
  return (
    <AppBar
      sx={{
        height: '60px',
        position: 'sticky',
        backgroundColor: '#FFFFFF',
        color: '#899499',
      }}
      elevation={0}
    >
      <Toolbar
        sx={(theme) => ({
          paddingLeft: 40,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 1,
          },
        })}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/images/Kmart-logo.png`}
          alt="Kmart"
        />
        <ImageTarget
          src={`${process.env.PUBLIC_URL}/images/targetlogo.png`}
          alt="Target"
        />

        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={(theme) => ({
              marginRight: 10,
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
              [theme.breakpoints.up('sm')]: {
                display: 'inline-block',
              },
              [theme.breakpoints.down('xl')]: {
                marginRight: 30,
              },
            })}
          >
            <SearchBar
              style={{
                marginBottom: 30,
                width: '400px',
                margin: 'auto',
              }}
              onSearch={(text) => {
                setSearchText(text);
              }}
            />
          </Box>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            })}
            style={{ margin: '8px 20px 0px 10px' }}
          >
            {profilePic === null && <Avatar sx={{ width: 36, height: 36 }} />}
            {(profilePic === false && (
              <Avatar sx={{ width: 36, height: 36 }}>
                {graphProfile.nameInitials}
              </Avatar>
            )) || <Avatar src={profilePic} />}
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: 22,
            }}
          >
            <IconButton
              sx={[
                () => ({
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }),
              ]}
              onClick={() =>
                permissionManager?.isUserSupplier(userPermissions)
                  ? window.open(process.env.REACT_APP_SUPPLIER_SERVICENOW_URL)
                  : window.open(process.env.REACT_APP_KMART_SERVICENOW_URL)
              }
              size="large"
            >
              <BugReportOutlinedIcon
                sx={(theme) => ({
                  marginBottom: '-16px',
                  color: '#000',
                  fontSize: 30,
                  [theme.breakpoints.down('lg')]: {
                    fontSize: 30,
                    marginBottom: '-12px',
                  },
                })}
              />
            </IconButton>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                [theme.breakpoints.down('lg')]: {
                  fontSize: 11,
                },
              })}
            >
              Report Issue
            </Typography>
          </Box>

          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <IconButton
              sx={[
                () => ({
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }),
              ]}
              onClick={signOutUser}
              size="large"
            >
              <InputIcon
                sx={(theme) => ({
                  marginBottom: '-16px',
                  color: '#000',
                  [theme.breakpoints.down('lg')]: {
                    fontSize: 18,
                    marginBottom: '-12px',
                  },
                })}
              />
            </IconButton>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                [theme.breakpoints.down('lg')]: {
                  fontSize: 11,
                },
              })}
            >
              Logout
            </Typography>
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
