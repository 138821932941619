import React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

export default function SearchBar(props) {
  const { onSearch, ...other } = props;

  return (
    <Paper
      component="form"
      sx={(theme) => ({
        padding: '4px 12px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        maxWidth: 600,
        [theme.breakpoints.down('md')]: {
          maxWidth: 300,
        },
      })}
      {...other}
      elevation={1}
    >
      <InputBase
        sx={(theme) => ({
          marginLeft: theme.spacing(1),
          flex: 1,
        })}
        placeholder="Search Apps"
        inputProps={{ 'aria-label': 'search your apps' }}
        color="primary"
        onChange={(event) => onSearch(event.target.value)}
      />
      <IconButton
        type="submit"
        sx={{ padding: 0 }}
        aria-label="search"
        size="large"
      >
        <SearchIcon sx={{ color: '#000' }} />
      </IconButton>
    </Paper>
  );
}

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
};
