export const iconColors = [
  '#e91e63',
  '#9c27b0',
  '#ff5722',
  '#673ab7',
  '#3f51b5',
  '#ffc107',
  '#9e9e9e',
  '#ffd180',
  '#ff8a80',
  '#F70776',
  '#394359',
  '#568564',
  '#2196f3',
];

export const shadowColors = [
  '#FDEBF1',
  '#F8E9FA',
  '#FFDDD3',
  '#F1ECF9',
  '#EDEFF9',
  '#FFF1D9',
  '#ECECEC',
  '#FFF9E8',
  '#FFE8E6',
  '#FDCCE3',
  '#CED3DE',
  '#D6E7DB',
  '#EBF5FE',
];
