import React from 'react';
import { Navigate } from 'react-router-dom';
import MainLayout from './layouts/main_layout';
import WelcomeView from './views/dashboard/welcome';
import LandingPage from './views/landing_page';

export const unauthenticatedRoutes = [
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/*',
    element: <Navigate to="/" />,
  },
];

const routes = [
  {
    path: 'apps',
    element: <MainLayout />,
    private: true,
    children: [
      {
        index: true,
        element: <WelcomeView />,
      },
      {
        path: '*',
        element: <Navigate to="/apps" />,
        private: true,
      },
    ],
  },
  ...unauthenticatedRoutes,
];

export default routes;
