/* eslint-disable no-undef */
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import getProfilePic from '../services/image.service';
import { msalApp } from '../utils/auth_utils';

const initailState = {
  userPermissions: {},
  graphProfile: {},
  userAccount: {},
  profilePic: undefined,
  userSessionExpired: false,
  accessTokens: null,
};

const store = (set) => ({
  ...initailState,
  updateUserPermissions: (userPermissions) => {
    set((state) => {
      state.userPermissions =
        process.env.REACT_APP_ENV === 'development' &&
        process.env?.REACT_APP_UI_DEV_MODE === 'true'
          ? { ust: 'internal' }
          : userPermissions;
    });
  },
  updateAccessTokens: (accessTokens) =>
    set((state) => {
      state.accessTokens = accessTokens;
    }),
  updateGraphProfile: (graphProfile) =>
    set((state) => {
      state.graphProfile = graphProfile;
    }),
  updateProfilePic: async () => {
    const profilePic = await getProfilePic();
    set((state) => {
      state.profilePic = profilePic;
    });
  },
  updateUserAccount: (useraccount) =>
    set((state) => {
      state.userAccount = useraccount;
    }),
  signOutUser: async () => {
    msalApp.logout();
  },
  updateUserSession: () => {
    set((state) => {
      state.userSessionExpired = true;
    });
  },
});

const useAuthProviderStore = create(
  immer(devtools(store, { name: 'AuthProviderStore' })),
);

export default useAuthProviderStore;
