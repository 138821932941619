export const apiVersion = '/osb/api/';

export const masterDataApi = {};

export const authorizationApi = {
  default: 'v3/authorize',
  logout: 'logout',
};

export const appsApi = {
  getApps: 'v3/apps',
};
