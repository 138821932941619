export const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');
export const camelize = (str) => {
  // eslint-disable-next-line func-names
  return str.replace(/_+(.)/g, function (match, chr) {
    return chr.toUpperCase();
  });
};
export const getNameInitials = (name) => {
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};
