import { apiService } from '../../../services';
import { appsApi } from '../../../configs';

const getApps = () => {
  return apiService.get(appsApi.getApps).then((apps) => apps.data);
};

const appsAction = {
  getApps,
};

export default appsAction;
