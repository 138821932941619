import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { msalApp, GRAPH_REQUESTS } from '../../utils/auth_utils';
import useAuthProviderStore from '../../stores/auth_provider.store';
import { Box, Button, Typography } from '@mui/material';

function LandingPage() {
  const { userAccount } = useAuthProviderStore((state) => ({
    userAccount: state.userAccount,
  }));
  const onLogin = () => {
    msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
  };
  return (
    <Box
      sx={(theme) => ({
        backgroundPosition: 'left bottom, right top',
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: ' 80%, 56%',
        height: '100dvh',
        [theme.breakpoints.down('sm')]: {
          backgroundSize: '189%, 52%',
        },
      })}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-left.png),url(${process.env.PUBLIC_URL}/images/bg-right.png)`,
      }}
    >
      <header
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          color: 'rgba(255, 255, 255, 0.5)',
          left: 0,
          padding: '1em 1em',
          position: 'absolute',
          top: 0,
        }}
      >
        <Box sx={{ margin: '1em ', width: '10em' }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/Kmart-logo.png`}
            alt="kmart-logo"
            style={{ width: '100%' }}
          />
        </Box>
      </header>
      <Box style={{ padding: '10em 4em' }}>
        <Box
          sx={(theme) => ({
            maxWidth: 600,
            textAlign: 'justify',
            backgroundPosition: 'left bottom, right top',
            backgroundRepeat: ' no-repeat, no-repeat',
            backgroundSize: '80%, 56%',
            [theme.breakpoints.down('sm')]: {
              paddingTop: 25,
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: 15,
            },
          })}
        >
          <Box className="one">
            <Box
              sx={(theme) => ({
                major: {
                  padding: '12px 0px',
                  [theme.breakpoints.down('md')]: {
                    padding: 0,
                    width: 200,
                  },
                },
              })}
            >
              <Box
                sx={{
                  color: '#3c415d',
                  textShadow:
                    '0 0 1px rgb(0 0 0 / 31%), 0 3px 4px rgb(0 0 0 / 25%)',
                }}
              >
                <Typography gutterBottom variant="h3">
                  Supplier Portal
                </Typography>
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                margin: '0 0 2em 0',
                color: '#666',
                [theme.breakpoints.down('md')]: {
                  width: 200,
                  textAlign: 'initial',
                },
              })}
            >
              <Typography gutterBottom variant="body1">
                A Supplier portal, or also known as a vendor portal, is an
                internet-based collaboration tool that allows us to manage and
                connect with third party suppliers.
              </Typography>
            </Box>
            <Box>
              {!_.isEmpty(userAccount) ? (
                <Link to="apps">
                  <Button
                    sx={{
                      appearance: 'none',
                      transition: `background-color 0.2s ease-in-out, color 0.2s ease-in-out,
                        border-color 0.2s ease-in-out`,
                      border: 'solid 3px #3c415d',
                      color: '#fff !important',
                      backgroundColor: '#3c415d',
                      cursor: 'pointer',
                      display: 'inline-block',
                      fontWeight: 400,
                      fontSize: 'inherit',
                      height: 'calc(2.1em + 6px)',
                      lineHeight: '2.1em',
                      minWidth: '10em',
                      padding: '0 1em',
                      textAlign: 'center',
                      textDecoration: 'none',
                      whiteSpace: 'nowrap',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        borderColor: '#3c415d',
                        color: '#3c415d !important',
                      },
                    }}
                    data-test-id="login-apps"
                  >
                    Take me to Portal
                  </Button>
                </Link>
              ) : (
                <Button
                  sx={{
                    appearance: 'none',
                    transition: `background-color 0.2s ease-in-out, color 0.2s ease-in-out,
                    border-color 0.2s ease-in-out`,
                    border: 'solid 3px #3c415d',
                    color: '#fff !important',
                    backgroundColor: '#3c415d',
                    cursor: 'pointer',
                    display: 'inline-block',
                    fontWeight: 400,
                    fontSize: 'inherit',
                    height: 'calc(2.1em + 6px)',
                    lineHeight: '2.1em',
                    minWidth: '10em',
                    padding: '0 1em',
                    textAlign: 'center',
                    textDecoration: 'none',
                    whiteSpace: 'nowrap',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      borderColor: '#3c415d',
                      color: '#3c415d !important',
                    },
                  }}
                  onClick={onLogin}
                  data-test-id="login-apps"
                >
                  Login to Portal
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LandingPage;
